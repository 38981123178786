import React, { useState, useEffect, useRef } from 'react';
import { Container, TextField, MenuItem, Button, Select, Typography, Card, CardContent, ThemeProvider, createTheme, InputLabel } from '@mui/material';
import axios from 'axios';

import { toast, ToastContainer } from "react-toastify";

const theme = createTheme({
	palette: {
		primary: {
			main: '#1976d2',  // This will make the primary color blue
		},
	},
});

function DccPage() {
	// Separate state for designation and caste
	const [isLoading, setIsLoading] = useState(false);
	const [selectCities, setSelectCities] = useState([]);	
	const [selectCorporations, setSelectCorporations] = useState([]);	
	const [selectZones, setSelectZones] = useState([]);	
	const [selectParliament, setSelectParliament] = useState([]);	
	const [selectAssemblies, setSelectAssemblies] = useState([]);	
	const [selectDesignations, setSelectDesignations] = useState([]);	
	const [selectCastes, setSelectCastes] = useState([]);	
	const shouldLog = useRef(true);

	useEffect(() => {
		if (shouldLog.current) {
			shouldLog.current = false;

			//Get City List
			fetchCity();
			fetchCorporationMaster();
			fetchZone();
			fetchParliament();
			fetchAssembly();
			fetchDesignation();
			fetchCaste();
		}
		// eslint-disable-next-line
	}, []);


	const fetchCity = async() => {
		try {
			const response = await axios.get('http://unitech.agency/Agency/Congress/admin/api/v1/get_city', {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				}
			});

			if (response.status === 200 && response.data.status === 200) {
				console.log(response.data.cities)
				setSelectCities(response.data.cities);
			} else {
				console.log(response.data.message || 'Failed to create city. API returned an error!');
			}
		} catch (error) {
			console.error('API Error:', error);
			alert('Failed to create city. Please try again!');
		}
	};

	const fetchCorporationMaster = async() => {
		try {
			const response = await axios.get('http://unitech.agency/Agency/Congress/admin/api/v1/get_CorporstionMaster', {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				}
			});

			if (response.status === 200 && response.data.status === 200) {
				setSelectCorporations(response.data.CorporstionMaster);
			} else {
				alert(response.data.message || 'Failed to create city. API returned an error!');
			}
		} catch (error) {
			console.error('API Error:', error);
			alert('Failed to create CorporationMaster. Please try again!');
		}
	};

	const fetchZone = async() => {
		try {
			const response = await axios.get('http://unitech.agency/Agency/Congress/admin/api/v1/get_Zone', {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				}
			});

			if (response.status === 200 && response.data.status === 200) {
				setSelectZones(response.data.Zone);
			} else {
				console.log(response.data.message || 'Failed to create city. API returned an error!');
			}
		} catch (error) {
			console.error('API Error:', error);
			alert('Failed to create Zone. Please try again!');
		}
	};

	const fetchParliament = async() => {
		try {
			const response = await axios.get('http://unitech.agency/Agency/Congress/admin/api/v1/get_Parliament', {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				}
			});

			if (response.status === 200 && response.data.status === 200) {
				setSelectParliament(response.data.Parliament);
			} else {
				console.log(response.data.message || 'Failed to create city. API returned an error!');
			}
		} catch (error) {
			console.error('API Error:', error);
			alert('Failed to create Parliament. Please try again!');
		}
	};

	const fetchAssembly = async() => {
		try {
			const response = await axios.get('http://unitech.agency/Agency/Congress/admin/api/v1/get_Assembly', {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				}
			});

			if (response.status === 200 && response.data.status === 200) {
				setSelectAssemblies(response.data.Assembly);
			} else {
				console.log(response.data.message || 'Failed to create Assembly. API returned an error!');
			}
		} catch (error) {
			console.error('API Error:', error);
			alert('Failed to create Assembly. Please try again!');
		}
	};

	const fetchDesignation = async() => {
		try {
			const response = await axios.get('http://unitech.agency/Agency/Congress/admin/api/v1/get_Designation', {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				}
			});

			if (response.status === 200 && response.data.status === 200) {
				setSelectDesignations(response.data.Designation);
			} else {
				console.log(response.data.message || 'Failed to create Designation. API returned an error!');
			}
		} catch (error) {
			console.error('API Error:', error);
			alert('Failed to create Designation. Please try again!');
		}
	};

	const fetchCaste = async() => {
		try {
			const response = await axios.get('http://unitech.agency/Agency/Congress/admin/api/v1/get_caste', {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				}
			});

			if (response.status === 200 && response.data.status === 200) {
				setSelectCastes(response.data.castes);
			} else {
				console.log(response.data.message || 'Failed to create caste. API returned an error!');
			}
		} catch (error) {
			console.error('API Error:', error);
			alert('Failed to create caste. Please try again!');
		}
	};

	const [formData, setFormData] = useState({
		city_id : '',
		corporations_id: '',
		zone_id: '',
		parliament_id: '',
		assembly_id: '',
		designations_id: '',
		personName:'',
		aadharId:'',
		voterId:'',
		voterSno:'',
		wardNo : '',
		boothNo: '',
		mobile : '',
		email : '',
		dob: '',
		gender: '',
		caste_id: '',
		created_by : '1',
		dateOfApplication: '',
		photo: null,
	});

	const handleChange = (event) => {
		const { name, value } = event.target;

		// Dynamically update the field based on the "name" attribute
		setFormData((prev) => ({
			...prev,
			[name]: value
		}));
	};
 
	// const handleChange = (e) => {
	// 	const { name, value } = e.target;
	// 	setFormData({
	// 		...formData,
	// 		[name]: value,
	// 	});
	// };

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setFormData({
			...formData,
			photo: file,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		const formDataToSend = new FormData();

		// Append all fields correctly
		Object.keys(formData).forEach((key) => {
			if (formData[key]) {
				formDataToSend.append(key, formData[key]);
			}
		});

		// Append image if it exists
		if (formData.photo) {
			formDataToSend.append('image', formData.photo);
		}

		const apiUrl = 'http://unitech.agency/Agency/Congress/admin/api/v1/add_dcc_member';

		try {
			const response = await axios.post(apiUrl, formDataToSend, {
				headers: {
					'Accept': 'application/json',
				},
			});

			console.log('Response from API:', response.data);

			if (response.data.status === 200) {
				// toast.success(response.data.message, {
				//     position: toast.POSITION.TOP_RIGHT,
				//     autoClose: 1000,
				// });
				toast(response.data.message)
			} else {
				// toast.error(response.data.message, {
				//     position: toast.POSITION.TOP_RIGHT,
				//     autoClose: 1000,
				// });
				toast(response.data.message)
			}

			// Reset form
			setFormData({
				city_id: '',
				corporations_id: '',
				zone_id: '',
				parliament_id: '',
				assembly_id: '',
				designations_id: '',
				personName: '',
				aadharId: '',
				voterId: '',
				voterSno: '',
				wardNo: '',
				boothNo: '',
				mobile: '',
				email: '',
				dob: '',
				gender: '',
				caste_id: '',
				photo: null,
			});

		} catch (error) {
			if (error.response) {
				toast(error.response.data.message);
				console.error('Error Response:', error.response.data);
				// toast.error(`Error: ${error.response.data.message || 'Something went wrong'}`, {
				//     position: toast.POSITION.TOP_RIGHT,
				//     autoClose: 3000,
				// });
			} else if (error.request) {
				toast('No response from server. Please try again.');
				console.error('No Response:', error.request);
				// toast.error('No response from server. Please try again.', {
				//     position: toast.POSITION.TOP_RIGHT,
				//     autoClose: 3000,
				// });
			} else {
				toast(error.message);
				console.error('Error', error.message);
				// toast.error(`Error: ${error.message}`, {
				//     position: toast.POSITION.TOP_RIGHT,
				//     autoClose: 3000,
				// });
			}
		} finally {
			setIsLoading(false);  // Hide loader after submission
		}
	};


	const photoPreview = formData.photo ? URL.createObjectURL(formData.photo) : '';

	return (
		<ThemeProvider theme={theme}>
			<Container maxWidth="lg" className="mt-5">

				<Card>
					<CardContent>
						<Typography variant="h5" align="center" gutterBottom style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom:40 }}>
						    <img 
						        src="congressflag.png"  // Path to your image
						        alt="Logo"
						        style={{ width: '50px', height: '50px', marginRight: '10px' }}
						    />
						    District Congress Committee Form
						</Typography>
						<form onSubmit={handleSubmit}>
							<div className="row g-3 uniform-container">

								{/* Name of the City */}
								<div className="col-12 col-sm-6 col-md-6 col-lg-4" style={{marginBottom:10}}>
									<TextField
							            label="Select City"
							            variant="outlined"
							            name="city_id"
							            value={formData.city_id}
							            onChange={handleChange}
							            className="uniform-input"
							            required
							            fullWidth
							            select
							        >
							            {selectCities.map((city) => (
							              <MenuItem key={city.id} value={city.id}>
							                {city.name}
							              </MenuItem>
							            ))}
							        </TextField>
								</div>

								{/* Corporation */}
								<div className="col-12 col-sm-6 col-md-6 col-lg-4" style={{marginBottom:10}}>
									<TextField
							            label="Select Corporations"
							            variant="outlined"
							            name="corporations_id"
							            value={formData.corporations_id}
							            onChange={handleChange}
							            className="uniform-input"
							            required
							            fullWidth
							            select
							        >
							            {selectCorporations.map((corporations) => (
							              <MenuItem key={corporations.id} value={corporations.id}>
							                {corporations.name}
							              </MenuItem>
							            ))}
							        </TextField>
								</div>

								{/* Zone */}
								<div className="col-12 col-sm-6 col-md-6 col-lg-4" style={{marginBottom:10}}>
									<TextField
							            label="Select Zone"
							            variant="outlined"
							            name="zone_id"
							            value={formData.zone_id}
							            onChange={handleChange}
							            className="uniform-input"
							            required
							            fullWidth
							            select
							        >
							            {selectZones.map((zone) => (
							              <MenuItem key={zone.id} value={zone.id}>
							                {zone.name}
							              </MenuItem>
							            ))}
							        </TextField>
								</div>

								{/* Name of the Parliament */}
								<div className="col-12 col-sm-6 col-md-6 col-lg-4" style={{marginBottom:10}}>
									<TextField
							            label="Select Parliament"
							            variant="outlined"
							            name="parliament_id"
							            value={formData.parliament_id}
							            onChange={handleChange}
							            className="uniform-input"
							            required
							            fullWidth
							            select
							        >
							            {selectParliament.map((parliament) => (
							              <MenuItem key={parliament.id} value={parliament.id}>
							                {parliament.name}
							              </MenuItem>
							            ))}
							        </TextField>
								</div>

								{/* Name of the Assembly */}
								<div className="col-12 col-sm-6 col-md-6 col-lg-4" style={{marginBottom:10}}>
									<TextField
							            label="Select Assembly"
							            variant="outlined"
							            name="assembly_id"
							            value={formData.assembly_id}
							            onChange={handleChange}
							            className="uniform-input"
							            required
							            fullWidth
							            select
							        >
							            {selectAssemblies.map((assembly) => (
							              <MenuItem key={assembly.id} value={assembly.id}>
							                {assembly.name}
							              </MenuItem>
							            ))}
							        </TextField>
								</div>

								{/* Name of the Assembly */}
								<div className="col-12 col-sm-6 col-md-6 col-lg-4" style={{marginBottom:10}}>
									<TextField
							            label="Select Designations"
							            variant="outlined"
							            name="designations_id"
							            value={formData.designations_id}
							            onChange={handleChange}
							            className="uniform-input"
							            required
							            fullWidth
							            select
							        >
							            {selectDesignations.map((designations) => (
							              <MenuItem key={designations.id} value={designations.id}>
							                {designations.name}
							              </MenuItem>
							            ))}
							        </TextField>
								</div>

								{/* Name  */}
								<div className="col-12 col-sm-6 col-md-6 col-lg-4" style={{marginBottom:10}}>
									<TextField
							            label="Name"
							            variant="outlined"
							            name="personName"
							            value={formData.personName}
							            onChange={handleChange}
							            className="uniform-input"
							            required
							            fullWidth
							        />
								</div>

								{/* Aadhar ID  */}
								<div className="col-12 col-sm-6 col-md-6 col-lg-4" style={{marginBottom:10}}>
							        <TextField
							            label="Aadhar ID"
							            variant="outlined"
							            name="aadharId"
							            type="number"
							            value={formData.aadharId}
							            onChange={handleChange}
							            className="uniform-input"
							            required
							            fullWidth
										inputProps={{ pattern: '[0-9]*' }}  
							        />
								</div>
								{/* Voter ID  */}
								<div className="col-12 col-sm-6 col-md-6 col-lg-4" style={{marginBottom:10}}>
									<TextField
							            label="Voter ID"
							            variant="outlined"
							            name="voterId"
							            value={formData.voterId}
							            onChange={handleChange}
							            className="uniform-input"
							            required
							            fullWidth  
							        />
								</div>
								{/* Voter's Serial Number  */}
								<div className="col-12 col-sm-6 col-md-6 col-lg-4" style={{marginBottom:10}}>
									<TextField
							            label="Voter's Serial Number"
							            variant="outlined"
							            name="voterSno"
							            value={formData.voterSno}
							            onChange={handleChange}
							            className="uniform-input"
							            required
							            fullWidth  
							        />
								</div>
								{/* Ward Number  */}
								<div className="col-12 col-sm-6 col-md-6 col-lg-4" style={{marginBottom:10}}>
									<TextField
							            label="Ward No."
							            variant="outlined"
							            name="wardNo"
							            value={formData.wardNo}
							            onChange={handleChange}
							            className="uniform-input"
							            required
							            fullWidth  
							        />
								</div>
								{/* Booth Number  */}
								<div className="col-12 col-sm-6 col-md-6 col-lg-4" style={{marginBottom:10}}>
									<TextField
							            label="Booth No."
							            variant="outlined"
							            name="boothNo"
							            type="number"
							            value={formData.boothNo}
							            onChange={handleChange}
							            className="uniform-input"
							            required
							            fullWidth  
							        />
								</div>
								{/* Mobile Number  */}
								<div className="col-12 col-sm-6 col-md-6 col-lg-4" style={{marginBottom:10}}>
									<TextField
							            label="Mobile Number"
							            variant="outlined"
							            name="mobile"
							            type="number"
							            value={formData.mobile}
							            onChange={handleChange}
							            className="uniform-input"
							            required
							            fullWidth  
							        />
								</div>
								{/* Email ID  */}
								<div className="col-12 col-sm-6 col-md-6 col-lg-4" style={{marginBottom:10}}>
									<TextField
							            label="Email ID"
							            variant="outlined"
							            name="email"
							            type="number"
							            value={formData.email}
							            onChange={handleChange}
							            className="uniform-input"
							            required
							            fullWidth  
							        />
								</div>
								{/* Date of Birth */}
								<div className="col-12 col-sm-6 col-md-6 col-lg-4" style={{marginBottom:10}}>
									<TextField
							            label="Date of Birth"
							            variant="outlined"
							            type="date"
							            name="dob"
							            value={formData.dob}
							            onChange={handleChange}
							            className="uniform-input"
							            required
							            fullWidth
							            InputLabelProps={{ shrink: true }}
							        />
								</div>

								{/* Caste (Dropdown) */}
								<div className="col-12 col-sm-6 col-md-6 col-lg-4" style={{marginBottom:10}}>
									<TextField
							            label="Select Caste"
							            variant="outlined"
							            name="caste_id"
							            value={formData.caste_id}
							            onChange={handleChange}
							            className="uniform-input"
							            required
							            fullWidth
							            select
							        >
							            {selectCastes.map((caste) => (
							              <MenuItem key={caste.id} value={caste.id}>
							                {caste.name}
							              </MenuItem>
							            ))}
							        </TextField>
								</div>

								{/* Gender (Radio Buttons) */}
								<div className="col-12 col-sm-6 col-md-6 col-lg-4" style={{marginBottom:10}}>
									<Typography variant="subtitle1">Gender<span>*</span></Typography>
									<div className="d-flex justify-content-start">
										<label className="me-2">
											<input
												type="radio"
												name="gender"
												value="Male"
												checked={formData.gender === 'Male'}
												onChange={handleChange}
												required
											/>
											<span className="ms-1">Male</span>
										</label>
										<label className="me-2">
											<input
												type="radio"
												name="gender"
												value="Female"
												checked={formData.gender === 'Female'}
												onChange={handleChange}
												required
											/>
											<span className="ms-1">Female</span>
										</label>
										<label>
											<input
												type="radio"
												name="gender"
												value="Others"
												checked={formData.gender === 'Others'}
												onChange={handleChange}
												required
											/>
											<span className="ms-1">Others</span>
										</label>
									</div>
								</div>

								{/* Photo Upload */}
								<div className="col-12 col-sm-6 col-md-6 col-lg-4" style={{marginBottom:10}}>
									<Typography variant="subtitle1">Upload Photo</Typography>
									<input
										type="file"
										accept="image/*"
										onChange={handleFileChange}
										className="form-control"
									/>
									{formData.photo && (
										<img
											src={photoPreview}
											alt="Preview"
											className="mt-3"
											style={{ width: '150px', height: '150px', objectFit: 'cover' }}
										/>
									)}
								</div>
							</div>

							{/* Submit Button */}
							<div className="mt-3 text-center">
								<Button type="submit" disabled={isLoading} variant="contained" color="primary">
									{isLoading ? 'Submitting...' : 'Submit'}
								</Button>
							</div>
						</form>

						{isLoading && (
							<div className="loader-overlay">
								<div className="loader"></div>
							</div>
						)}
					</CardContent>
				</Card>
			</Container>
			< ToastContainer />
		</ThemeProvider>
	);
}

export default DccPage;
