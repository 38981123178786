import React from 'react';
import { useNavigate } from 'react-router-dom';  // For navigation
import { Button, TextField, Box, Typography, Paper, Container, Link } from "@mui/material";

const Dashboard = ({onLogOut}) => {
    const navigate = useNavigate();

    // Logout Handler
    const handleLogout = () => {
       
        // Redirect to login page
        onLogOut();
        navigate('/');
    };

    return (
        <div className="container-fluid bg-light min-vh-100">
            
            {/* Navbar with Logout */}
            <div className="navbar d-flex justify-content-between align-items-center p-3 text-white">
                <h2 className="text-center m-0">
                    <img 
                        src="../congressflag.png"  
                        alt="Logo"
                        style={{ width: '50px', height: '50px', marginRight: '10px' }}
                    />
                    Welcome
                </h2>

                {/* Logout Button */}
                <Button 
                    variant="contained" 
                    color="secondary" 
                    onClick={handleLogout}
                    style={{ backgroundColor: '#e74c3c', color: '#fff' }}
                >
                    Logout
                </Button>
            </div>

            {/* Stats Cards */}
            <div className="card-container">
                <div className="card">
                    <p className="cardHeader">DCC</p>
                    <p>1,200</p>
                </div>
                <div className="card">
                    <p className="cardHeader">BLOCK</p>
                    <p>45,000</p>
                </div>
                <div className="card">
                    <p className="cardHeader">MUNICIPALITY</p>
                    <p>750</p>
                </div>
                <div className="card">
                    <p className="cardHeader">TOWN</p>
                    <p>320</p>
                </div>
            </div>

            <style>{`
                .navbar {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background: #34495e;
                    color: white;
                    padding: 15px 20px;
                }

                .card {
                    padding: 2%;
                    width: 45%;
                    float: left;
                    margin: 2%;
                    background: #fff;
                    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
                    border-radius: 8px;
                    transition: 0.3s;
                    text-align: center;
                }

                .card:hover {
                    transform: translateY(-5px);
                }

                .cardHeader {
                    font-size: 18px;
                    font-weight: bold;
                    color: #ec6433;
                }

                .card p {
                    font-size: 20px;
                    color: #2c3e50;
                }

                @media screen and (max-width: 768px) {
                    .card {
                        width: 100%;
                    }
                }
            `}</style>
        </div>
    );
};

export default Dashboard;
