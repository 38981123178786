import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

function AssemblyMaster() {
	const [assemblyName, setAssemblyName] = useState("");
	const [assemblies, setAssemblies] = useState([]);
	const [editingIndex, setEditingIndex] = useState(null);
	const [editAssemblyName, setEditAssemblyName] = useState("");
	const shouldLog = useRef(true);

	useEffect(() => {
		if (shouldLog.current) {
			shouldLog.current = false;

			//Get Assembly List
			fetchAssembly();
		}
		// eslint-disable-next-line
	}, []);

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (assemblyName) {
			const formData = new URLSearchParams();
			formData.append('name', assemblyName);
			formData.append('user_id', '1');

			try {
				const response = await axios.post('http://unitech.agency/Agency/Congress/admin/api/v1/add_Assembly', formData, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					}
				});

				if (response.status === 200 && response.data.status === 200) {
					// Add new Assembly to the list
					setAssemblies([...assemblies, { name: assemblyName }]);
					setAssemblyName(""); // Reset input after submit
				} else {
					alert(response.data.message || 'Failed to create city. API returned an error!');
				}
			} catch (error) {
				console.error('API Error:', error);
				alert('Failed to create Assembly. Please try again!');
			}
		}
	};

	const fetchAssembly = async() => {
		try {
			const response = await axios.get('http://unitech.agency/Agency/Congress/admin/api/v1/get_Assembly', {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				}
			});

			if (response.status === 200 && response.data.status === 200) {
				setAssemblies(response.data.Assembly);
				setAssemblyName(""); // Reset input after submit 
			} else {
				console.log(response.data.message || 'Failed to create Assembly. API returned an error!');
			}
		} catch (error) {
			console.error('API Error:', error);
			alert('Failed to create Assembly. Please try again!');
		}
	};

	const handleDelete = async(id, index) => {
		try {
			const response = await axios.get('http://unitech.agency/Agency/Congress/admin/api/v1/remove_Assembly/'+id, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				}
			});

			if (response.status === 200 && response.data.status === 200) {	
				// Remove Assembly from the list
				setAssemblies(assemblies.filter((_, i) => i !== index));
			} else {
				alert(response.data.message || 'Failed to create Assembly. API returned an error!');
			}
		} catch (error) {
			console.error('API Error:', error);
			alert('Failed to create Assembly. Please try again!');
		}
	};

	const handleEdit = (index) => {
		// Set editing state
		setEditingIndex(index);
		setEditAssemblyName(assemblies[index].name);
	};

	const handleUpdate = async(id,index) => {

		if (editAssemblyName) {
			const formData = new URLSearchParams();
			formData.append('id', id);
			formData.append('name', editAssemblyName);
			formData.append('user_id', '1');

			try {
				const response = await axios.post('http://unitech.agency/Agency/Congress/admin/api/v1/edit_Assembly', formData, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					}
				});

				if (response.status === 200 && response.data.status === 200) {
					// Update Assembly name
					const updatedAssemblies = [...assemblies];
					updatedAssemblies[index].name = editAssemblyName;
					setAssemblies(updatedAssemblies);
					setEditingIndex(null);
					setEditAssemblyName(""); // Clear edit input
				} else {
					alert(response.data.message || 'Failed to create city. API returned an error!');
				}
			} catch (error) {
				console.error('API Error:', error);
				alert('Failed to create Assembly. Please try again!');
			}
		}
	};

	return (
		<div className="container-fluid bg-light min-vh-100">
			<h4 className="text-center">
				<img 
					src="../congressflag.png"  // Path to your image
					alt="Logo"
					style={{ width: '50px', height: '50px', marginRight: '10px' }}
				/>
				Assembly
			</h4>
			<p className="text-center">Add a new Assembly here</p>

			{/* Form for adding new Assembly */}
			<div>
				<form onSubmit={handleSubmit}>
					<div className="input-group" style={{ display: 'flex', justifyContent: 'space-between' }}>
						<TextField 
							label="Assembly Name" 
							variant="outlined" 
							size="small"
							value={assemblyName}
							onChange={(e) => setAssemblyName(e.target.value)} 
							required
							style={{ flexGrow: 1 }}
						/>
						<Button 
							variant="contained" 
							color="primary" 
							type="submit" 
							style={{ marginLeft: '10px', alignSelf: 'center' }}
						>
							Add Assembly
						</Button>
					</div>
				</form>
			</div>

			{/* Table to display Assemblies */}
			<TableContainer component={Paper} style={{ marginTop: '20px' }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell><strong>Sl. No.</strong></TableCell>
							<TableCell><strong>Assembly Name</strong></TableCell>
							<TableCell><strong>Actions</strong></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{assemblies.map((assembly, index) => (
							<TableRow key={index}>
								<TableCell>{index + 1}</TableCell>
								<TableCell>
									{editingIndex === index ? (
										<TextField 
											value={editAssemblyName}
											onChange={(e) => setEditAssemblyName(e.target.value)}
											size="small"
											fullWidth
										/>
									) : (
										assembly.name
									)}
								</TableCell>
								<TableCell>
									{editingIndex === index ? (
										<Button 
											variant="contained" 
											color="primary" 
											onClick={() => handleUpdate(index)}
										>
											Update
										</Button>
									) : (
										<>
											<Button 
												variant="contained" 
												color="primary" 
												style={{ marginRight: '8px' }}
												onClick={() => handleEdit(index)}
											>
												Update
											</Button>
											<Button 
												variant="contained" 
												color="secondary"
												onClick={() => handleDelete(index)}
											>
												Delete
											</Button>
										</>
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

export default AssemblyMaster;