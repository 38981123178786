import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

function ZoneMaster() {
	const [zoneName, setZoneName] = useState("");
	const [zones, setZones] = useState([]);
	const [editingIndex, setEditingIndex] = useState(null);
	const [editZoneName, setEditZoneName] = useState("");
	const shouldLog = useRef(true);

	useEffect(() => {
		if (shouldLog.current) {
			shouldLog.current = false;

			//Get Zone List
			fetchZone();
		}
		// eslint-disable-next-line
	}, []);

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (zoneName) {
			const formData = new URLSearchParams();
			formData.append('name', zoneName);
			formData.append('user_id', '1');

			try {
				const response = await axios.post('http://unitech.agency/Agency/Congress/admin/api/v1/add_Zone', formData, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					}
				});

				if (response.status === 200 && response.data.status === 200) {
					setZones([...zones, { name: zoneName }]);
					setZoneName(""); // Reset input after submit
				} else {
					alert(response.data.message || 'Failed to create Zone. API returned an error!');
				}
			} catch (error) {
				console.error('API Error:', error);
				alert('Failed to create Zone. Please try again!');
			}
		}
	};

	const fetchZone = async() => {
		try {
			const response = await axios.get('http://unitech.agency/Agency/Congress/admin/api/v1/get_Zone', {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				}
			});

			if (response.status === 200 && response.data.status === 200) {
				setZones(response.data.Zone);
				setZoneName(""); 
			} else {
				console.log(response.data.message || 'Failed to create city. API returned an error!');
			}
		} catch (error) {
			console.error('API Error:', error);
			alert('Failed to create Zone. Please try again!');
		}
	};

	const handleDelete = async(id, index) => {
		try {
			const response = await axios.get('http://unitech.agency/Agency/Congress/admin/api/v1/remove_Zone/'+id, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				}
			});

			if (response.status === 200 && response.data.status === 200) {							
				setZones(zones.filter((_, i) => i !== index));
			} else {
				alert(response.data.message || 'Failed to create city. API returned an error!');
			}
		} catch (error) {
			console.error('API Error:', error);
			alert('Failed to create Zone. Please try again!');
		}
	};

	const handleEdit = (index) => {
		// Set editing state
		setEditingIndex(index);
		setEditZoneName(zones[index].name);
	};

	const handleUpdate = async(id,index) => {

		if (editZoneName) {
			const formData = new URLSearchParams();
			formData.append('id', id);
			formData.append('name', editZoneName);
			formData.append('user_id', '1');

			try {
				const response = await axios.post('http://unitech.agency/Agency/Congress/admin/api/v1/edit_Zone', formData, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					}
				});

				if (response.status === 200 && response.data.status === 200) {
					const updatedZones = [...zones];
					updatedZones[index].name = editZoneName;
					setZones(updatedZones);
					setEditingIndex(null);
					setEditZoneName(""); // Clear edit input
				} else {
					alert(response.data.message || 'Failed to create city. API returned an error!');
				}
			} catch (error) {
				console.error('API Error:', error);
				alert('Failed to create Zone. Please try again!');
			}
		}
	};

	return (
		<div className="container-fluid bg-light min-vh-100">
			<h4 className="text-center">
				<img 
					src="../congressflag.png"  // Path to your image
					alt="Logo"
					style={{ width: '50px', height: '50px', marginRight: '10px' }}
				/>
				Zone
			</h4>
			<p className="text-center">Add a new Zone here</p>

			{/* Form for adding new Zone */}
			<div>
				<form onSubmit={handleSubmit}>
					<div className="input-group" style={{ display: 'flex', justifyContent: 'space-between' }}>
						<TextField 
							label="Zone Name" 
							variant="outlined" 
							size="small"
							value={zoneName}
							onChange={(e) => setZoneName(e.target.value)} 
							required
							style={{ flexGrow: 1 }}
						/>
						<Button 
							variant="contained" 
							color="primary" 
							type="submit" 
							style={{ marginLeft: '10px', alignSelf: 'center' }}
						>
							Add Zone
						</Button>
					</div>
				</form>
			</div>

			{/* Table to display Zones */}
			<TableContainer component={Paper} style={{ marginTop: '20px' }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell><strong>Sl. No.</strong></TableCell>
							<TableCell><strong>Zone Name</strong></TableCell>
							<TableCell><strong>Actions</strong></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{zones.map((zone, index) => (
							<TableRow key={index}>
								<TableCell>{index + 1}</TableCell>
								<TableCell>
									{editingIndex === index ? (
										<TextField 
											value={editZoneName}
											onChange={(e) => setEditZoneName(e.target.value)}
											size="small"
											fullWidth
										/>
									) : (
										zone.name
									)}
								</TableCell>
								<TableCell>
									{editingIndex === index ? (
										<Button 
											variant="contained" 
											color="primary" 
											onClick={() => handleUpdate(zone.id,index)}
										>
											Update
										</Button>
									) : (
										<>
											<Button 
												variant="contained" 
												color="primary" 
												style={{ marginRight: '8px' }}
												onClick={() => handleEdit(index)}
											>
												Update
											</Button>
											<Button 
												variant="contained" 
												color="secondary"
												onClick={() => handleDelete(zone.id,index)}
											>
												Delete
											</Button>
										</>
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

export default ZoneMaster;