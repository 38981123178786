import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, TextField, Box, Typography, Paper, Container, Link } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Login({ onLoginSuccess }) {
	const [emailError, setEmailError] = useState(""); // Error state for email field
	const [passwordError, setPasswordError] = useState(""); // Error state for password field
	const [success, setSuccess] = useState(""); // Success state for login success message
	const navigate=useNavigate();

	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const email = data.get("email");
		const password = data.get("password");

		// Reset error messages on form submit
		setEmailError("");
		setPasswordError("");
		setSuccess(""); // Reset success message

		// Simple form validation for empty fields
		if (!email) {
			setEmailError("Please Enter Your Email ID.");
		}
		if (!password) {
			setPasswordError("Please Enter Your Password.");
		}

		// If both fields are filled, show success message
		if (email && password) {
			//navigate("/dcc");
			
			setSuccess("Login successful!"); // Show success message
			onLoginSuccess(); // Trigger login success to update the state in App.js
			navigate('/dashboard');
			
		}
	};

	return (
		<Container 
			component="main"
			maxWidth="xs"
			className="d-flex justify-content-center align-items-center form-container"
			// style={{
			// 	height: '100vh',
			// 	width: '100vw',
			// 	minHeight: '100vh',
			// 	minWidth: '100vw',
			// 	position: 'fixed',
			// 	top: 0,
			// 	left: 0,
			// 	display: 'flex',
			// 	justifyContent: 'center',
			// 	alignItems: 'center',
			// 	backgroundImage: `url('congressflag.png')`,
			// 	backgroundRepeat: 'no-repeat',
			// 	backgroundPosition: 'center center',
			// 	backgroundSize: 'cover'
			// }}
		>
			<Box sx={{ marginTop: 2 }} className="w-100">
				<div className="row">
					<div className="col-12 d-flex justify-content-center align-items-center">
						<Paper elevation={6} square sx={{ width: "100%", maxWidth: 400, padding: 3 }}>
							<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
								<Typography component="h1" variant="h5">
									<img 
				                        src="congressflag.png"  // Path to your image
				                        alt="Logo"
				                        style={{ width: '50px', height: '50px', marginRight: '10px' }}
				                    />
									Sign in
								</Typography>
								{success && <div className="text-success mb-2">{success}</div>} {/* Display success message */}

								<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
									<TextField
										margin="normal"
										required
										fullWidth
										id="email"
										label="Email Address"
										name="email"
										autoComplete="email"
										autoFocus
										error={!!emailError} // Show error state for email
										helperText={emailError} // Display the email error message
									/>
									<TextField
										margin="normal"
										required
										fullWidth
										name="password"
										label="Password"
										type="password"
										id="password"
										autoComplete="current-password"
										error={!!passwordError} // Show error state for password
										helperText={passwordError} // Display the password error message
									/>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										sx={{ mt: 3, mb: 2 }}
										onclick={handleSubmit}
									>
										Sign In
									</Button>
									<div className="row">
										<div className="col-6">
											<Link href="#" variant="body2">
												Forgot password?
											</Link>
										</div>
										<div className="col-6 text-end">
											<Link href="#" variant="body2">
												{"Sign Up"}
											</Link>
										</div>
									</div>
								</Box>
							</Box>
						</Paper>
					</div>
				</div>
			</Box>
		</Container>
	);
}
