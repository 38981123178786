import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

function DesignationMaster() {
	const [designationName, setDesignationName] = useState("");
	const [designations, setDesignations] = useState([]);
	const [editingIndex, setEditingIndex] = useState(null);
	const [editDesignationName, setEditDesignationName] = useState("");
	const shouldLog = useRef(true);

	useEffect(() => {
		if (shouldLog.current) {
			shouldLog.current = false;

			//Get Designation List
			fetchDesignation();
		}
		// eslint-disable-next-line
	}, []);

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (designationName) {
			const formData = new URLSearchParams();
			formData.append('name', designationName);
			formData.append('user_id', '1');

			try {
				const response = await axios.post('http://unitech.agency/Agency/Congress/admin/api/v1/add_Designation', formData, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					}
				});

				if (response.status === 200 && response.data.status === 200) {
					// Add new Designation to the list
					setDesignations([...designations, { name: designationName }]);
					setDesignationName(""); // Reset input after submit
				} else {
					alert(response.data.message || 'Failed to create Designation. API returned an error!');
				}
			} catch (error) {
				console.error('API Error:', error);
				alert('Failed to create Designation. Please try again!');
			}
		}
	};

	const fetchDesignation = async() => {
		try {
			const response = await axios.get('http://unitech.agency/Agency/Congress/admin/api/v1/get_Designation', {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				}
			});

			if (response.status === 200 && response.data.status === 200) {
				setDesignations(response.data.Designation);
				setDesignationName("");
			} else {
				console.log(response.data.message || 'Failed to create Designation. API returned an error!');
			}
		} catch (error) {
			console.error('API Error:', error);
			alert('Failed to create Designation. Please try again!');
		}
	};

	const handleDelete = async(id, index) => {
		try {
			const response = await axios.get('http://unitech.agency/Agency/Congress/admin/api/v1/remove_Designation/'+id, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				}
			});

			if (response.status === 200 && response.data.status === 200) {	
				// Remove Designation from the list
				setDesignations(designations.filter((_, i) => i !== index));
			} else {
				alert(response.data.message || 'Failed to create Designation. API returned an error!');
			}
		} catch (error) {
			console.error('API Error:', error);
			alert('Failed to create Designation. Please try again!');
		}
	};

	const handleEdit = (index) => {
		// Set editing state
		setEditingIndex(index);
		setEditDesignationName(designations[index].name);
	};

	const handleUpdate = async(id,index) => {

		if (editDesignationName) {
			const formData = new URLSearchParams();
			formData.append('id', id);
			formData.append('name', editDesignationName);
			formData.append('user_id', '1');

			try {
				const response = await axios.post('http://unitech.agency/Agency/Congress/admin/api/v1/edit_Designation', formData, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					}
				});

				if (response.status === 200 && response.data.status === 200) {
					// Update Designation name
					const updatedDesignations = [...designations];
					updatedDesignations[index].name = editDesignationName;
					setDesignations(updatedDesignations);
					setEditingIndex(null);
					setEditDesignationName(""); // Clear edit input
				} else {
					alert(response.data.message || 'Failed to create Designation. API returned an error!');
				}
			} catch (error) {
				console.error('API Error:', error);
				alert('Failed to create Designation. Please try again!');
			}
		}
	};

	return (
		<div className="container-fluid bg-light min-vh-100">
			<h4 className="text-center">
				<img 
					src="../congressflag.png"  // Path to your image
					alt="Logo"
					style={{ width: '50px', height: '50px', marginRight: '10px' }}
				/>
				Designation
			</h4>
			<p className="text-center">Add a new Designation here</p>

			{/* Form for adding new Designation */}
			<div>
				<form onSubmit={handleSubmit}>
					<div className="input-group" style={{ display: 'flex', justifyContent: 'space-between' }}>
						<TextField 
							label="Designation Name" 
							variant="outlined" 
							size="small"
							value={designationName}
							onChange={(e) => setDesignationName(e.target.value)} 
							required
							style={{ flexGrow: 1 }}
						/>
						<Button 
							variant="contained" 
							color="primary" 
							type="submit" 
							style={{ marginLeft: '10px', alignSelf: 'center' }}
						>
							Add Designation
						</Button>
					</div>
				</form>
			</div>

			{/* Table to display Designations */}
			<TableContainer component={Paper} style={{ marginTop: '20px' }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell><strong>Sl. No.</strong></TableCell>
							<TableCell><strong>Designation Name</strong></TableCell>
							<TableCell><strong>Actions</strong></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{designations.map((designation, index) => (
							<TableRow key={index}>
								<TableCell>{index + 1}</TableCell>
								<TableCell>
									{editingIndex === index ? (
										<TextField 
											value={editDesignationName}
											onChange={(e) => setEditDesignationName(e.target.value)}
											size="small"
											fullWidth
										/>
									) : (
										designation.name
									)}
								</TableCell>
								<TableCell>
									{editingIndex === index ? (
										<Button 
											variant="contained" 
											color="primary" 
											onClick={() => handleUpdate(designation.id, index)}
										>
											Update
										</Button>
									) : (
										<>
											<Button 
												variant="contained" 
												color="primary" 
												style={{ marginRight: '8px' }}
												onClick={() => handleEdit(index)}
											>
												Update
											</Button>
											<Button 
												variant="contained" 
												color="secondary"
												onClick={() => handleDelete(designation.id, index)}
											>
												Delete
											</Button>
										</>
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

export default DesignationMaster;