import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

function CasteMaster() {
	const [casteName, setCasteName] = useState("");
	const [castes, setCastes] = useState([]);
	const [editingIndex, setEditingIndex] = useState(null);
	const [editCasteName, setEditCasteName] = useState("");
	const shouldLog = useRef(true);

	useEffect(() => {
		if (shouldLog.current) {
			shouldLog.current = false;

			//Get Caste List
			fetchCaste();
		}
		// eslint-disable-next-line
	}, []);

	// Handle form submission to add caste
	const handleSubmit = async (event) => {
		event.preventDefault();
		if (casteName) {
			const formData = new URLSearchParams();
			formData.append('name', casteName);
			formData.append('user_id', '1');

			try {
				const response = await axios.post('http://unitech.agency/Agency/Congress/admin/api/v1/add_caste', formData, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					}
				});

				if (response.status === 200 && response.data.status === 200) {
					setCastes([...castes, { name: casteName }]);
					setCasteName(""); 
				} else {
					alert(response.data.message || 'Failed to create caste. API returned an error!');
				}
			} catch (error) {
				console.error('API Error:', error);
				alert('Failed to create caste. Please try again!');
			}
		}
	};

	const fetchCaste = async() => {
		try {
			const response = await axios.get('http://unitech.agency/Agency/Congress/admin/api/v1/get_caste', {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				}
			});

			if (response.status === 200 && response.data.status === 200) {
				setCastes(response.data.castes);
				setCasteName(""); 
			} else {
				console.log(response.data.message || 'Failed to create caste. API returned an error!');
			}
		} catch (error) {
			console.error('API Error:', error);
			alert('Failed to create caste. Please try again!');
		}
	};

	// Handle deleting a caste
	const handleDelete = async(id, index) => {
		try {
			const response = await axios.get('http://unitech.agency/Agency/Congress/admin/api/v1/remove_caste/'+id, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				}
			});

			if (response.status === 200 && response.data.status === 200) {			
				setCastes(castes.filter((_, i) => i !== index));
			} else {
				alert(response.data.message || 'Failed to create caste. API returned an error!');
			}
		} catch (error) {
			console.error('API Error:', error);
			alert('Failed to create caste. Please try again!');
		}
	};

	// Handle editing a caste
	const handleEdit = (index) => {
		setEditingIndex(index);
		setEditCasteName(castes[index].name);
	};

	// Handle updating a caste
	const handleUpdate = async(id,index) => {

		if (editCasteName) {
			const formData = new URLSearchParams();
			formData.append('id', id);
			formData.append('name', editCasteName);
			formData.append('user_id', '1');

			try {
				const response = await axios.post('http://unitech.agency/Agency/Congress/admin/api/v1/edit_caste', formData, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					}
				});

				if (response.status === 200 && response.data.status === 200) {
					const updatedCastes = [...castes];
					updatedCastes[index].name = editCasteName;
					setCastes(updatedCastes);
					setEditingIndex(null);
					setEditCasteName("");
				} else {
					alert(response.data.message || 'Failed to create caste. API returned an error!');
				}
			} catch (error) {
				console.error('API Error:', error);
				alert('Failed to create caste. Please try again!');
			}
		}
	};

	return (
		<div className="container-fluid bg-light min-vh-100">
			<h4 className="text-center mt-4">
				<img 
					src="../congressflag.png"  // Path to your image
					alt="Logo"
					style={{ width: '50px', height: '50px', marginRight: '10px' }}
				/>
				Caste Master
			</h4>
			<p className="text-center">Add a new caste here</p>

			{/* Form for adding new caste */}
			<div className="row justify-content-center mb-4">
				<form onSubmit={handleSubmit} className="col-12 col-md-8 col-lg-6">
					<div className="input-group flex-column flex-sm-row align-items-sm-center">
						<div className="d-flex mb-3 w-100 w-sm-auto">
						<TextField
							label="Caste Name"
							variant="outlined"
							size="small"
							value={casteName}
							onChange={(e) => setCasteName(e.target.value)}
							required
							className="w-100"
						/>
						
						<Button
							variant="contained"
							color="primary"
							type="submit"
							
							className=" col-auto ms-sm-2"
							
							
						>
							Add Caste
						</Button>
					</div>
					</div>
				</form>
			</div>

			{/* Table to display castes */}
			<TableContainer component={Paper} style={{ marginTop: '20px' }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell><strong>Sl. No.</strong></TableCell>
							<TableCell><strong>Caste Name</strong></TableCell>
							<TableCell><strong>Actions</strong></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{castes.map((caste, index) => (
							<TableRow key={index}>
								<TableCell>{index + 1}</TableCell>
								<TableCell>
									{editingIndex === index ? (
										<TextField
											value={editCasteName}
											onChange={(e) => setEditCasteName(e.target.value)}
											size="small"
											fullWidth
										/>
									) : (
										caste.name
									)}
								</TableCell>
								<TableCell>
									{editingIndex === index ? (
										<Button 
											variant="contained" 
											color="primary" 
											onClick={() => handleUpdate(caste.id, index)}
										>
											Update
										</Button>
									) : (
										<>
											<Button 
												variant="contained" 
												color="primary" 
												style={{ marginRight: '8px' }}
												onClick={() => handleEdit(index)}
											>
												Update
											</Button>
											<Button 
												variant="contained" 
												color="secondary"
												onClick={() => handleDelete(caste.id, index)}
											>
												Delete
											</Button>
										</>
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

export default CasteMaster;
