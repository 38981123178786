import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

function CorporationMaster() {
	const [corporationName, setCorporationName] = useState("");
	const [corporations, setCorporations] = useState([]);
	const [editingIndex, setEditingIndex] = useState(null);
	const [editCorporationName, setEditCorporationName] = useState("");
	const shouldLog = useRef(true);

	useEffect(() => {
		if (shouldLog.current) {
			shouldLog.current = false;

			//Get CorporationMaster List
			fetchCorporationMaster();
		}
		// eslint-disable-next-line
	}, []);

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (corporationName) {
			const formData = new URLSearchParams();
			formData.append('name', corporationName);
			formData.append('user_id', '1');

			try {
				const response = await axios.post('http://unitech.agency/Agency/Congress/admin/api/v1/add_CorporstionMaster', formData, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					}
				});

				if (response.status === 200 && response.data.status === 200) {
					setCorporations([...corporations, { name: corporationName }]);
					setCorporationName("");
				} else {
					alert(response.data.message || 'Failed to create city. API returned an error!');
				}
			} catch (error) {
				console.error('API Error:', error);
				alert('Failed to create CorporationMaster. Please try again!');
			}
		}
	};

	const fetchCorporationMaster = async() => {
		try {
			const response = await axios.get('http://unitech.agency/Agency/Congress/admin/api/v1/get_CorporstionMaster', {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				}
			});

			if (response.status === 200 && response.data.status === 200) {
				setCorporations(response.data.CorporstionMaster);
				setCorporationName(""); 
			} else {
				alert(response.data.message || 'Failed to create city. API returned an error!');
			}
		} catch (error) {
			console.error('API Error:', error);
			alert('Failed to create CorporationMaster. Please try again!');
		}
	};

	const handleDelete = async(id, index) => {
		try {
			const response = await axios.get('http://unitech.agency/Agency/Congress/admin/api/v1/remove_CorporstionMaster/'+id, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				}
			});

			if (response.status === 200 && response.data.status === 200) {							
				setCorporations(corporations.filter((_, i) => i !== index));
			} else {
				console.log(response.data.message || 'Failed to create CorporationMaster. API returned an error!');
			}
		} catch (error) {
			console.error('API Error:', error);
			alert('Failed to create CorporationMaster. Please try again!');
		}
	};

	const handleEdit = (index) => {
		// Set editing state
		setEditingIndex(index);
		setEditCorporationName(corporations[index].name);
	};

		// Handle updating a Corporstion
	const handleUpdate = async(id,index) => {

		if (editCorporationName) {
			const formData = new URLSearchParams();
			formData.append('id', id);
			formData.append('name', editCorporationName);
			formData.append('user_id', '1');

			try {
				const response = await axios.post('http://unitech.agency/Agency/Congress/admin/api/v1/edit_CorporstionMaster', formData, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					}
				});

				if (response.status === 200 && response.data.status === 200) {
					const updatedCorporations = [...corporations];
					updatedCorporations[index].name = editCorporationName;
					setCorporations(updatedCorporations);
					setEditingIndex(null);
					setEditCorporationName(""); // Clear edit input
				} else {
					alert(response.data.message || 'Failed to create city. API returned an error!');
				}
			} catch (error) {
				console.error('API Error:', error);
				alert('Failed to create CorporationMaster. Please try again!');
			}
		}
	};

	return (
		<div className="container-fluid bg-light min-vh-100">
			<h4 className="text-center">
				<img 
					src="../congressflag.png"  // Path to your image
					alt="Logo"
					style={{ width: '50px', height: '50px', marginRight: '10px' }}
				/>
				Corporation
			</h4>
			<p className="text-center">Add a new Corporation here</p>

			{/* Form for adding new Corporation */}
			<div>
				<form onSubmit={handleSubmit}>
					<div className="input-group" style={{ display: 'flex', justifyContent: 'space-between' }}>
						<TextField 
							label="Corporation Name" 
							variant="outlined" 
							size="small"
							value={corporationName}
							onChange={(e) => setCorporationName(e.target.value)} 
							required
							style={{ flexGrow: 1 }}
						/>
						<Button 
							variant="contained" 
							color="primary" 
							type="submit" 
							style={{ marginLeft: '10px', alignSelf: 'center' }}
						>
							Add CorporstionMaster
						</Button>
					</div>
				</form>
			</div>

			{/* Table to display Corporations */}
			<TableContainer component={Paper} style={{ marginTop: '20px' }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell><strong>Sl. No.</strong></TableCell>
							<TableCell><strong>Corporation Name</strong></TableCell>
							<TableCell><strong>Actions</strong></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{corporations.map((corporation, index) => (
							<TableRow key={index}>
								<TableCell>{index + 1}</TableCell>
								<TableCell>
									{editingIndex === index ? (
										<TextField 
											value={editCorporationName}
											onChange={(e) => setEditCorporationName(e.target.value)}
											size="small"
											fullWidth
										/>
									) : (
										corporation.name
									)}
								</TableCell>
								<TableCell>
									{editingIndex === index ? (
										<Button 
											variant="contained" 
											color="primary" 
											onClick={() => handleUpdate(corporation.id, index)}
										>
											Update
										</Button>
									) : (
										<>
											<Button 
												variant="contained" 
												color="primary" 
												style={{ marginRight: '8px' }}
												onClick={() => handleEdit(index)}
											>
												Update
											</Button>
											<Button 
												variant="contained" 
												color="secondary"
												onClick={() => handleDelete(corporation.id, index)}
											>
												Delete
											</Button>
										</>
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

export default CorporationMaster;
