import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

function ParliamentMaster() {
	const [parliamentName, setParliamentName] = useState("");
	const [parliaments, setParliaments] = useState([]);
	const [editingIndex, setEditingIndex] = useState(null);
	const [editParliamentName, setEditParliamentName] = useState("");
	const shouldLog = useRef(true);

	useEffect(() => {
		if (shouldLog.current) {
			shouldLog.current = false;

			//Get Parliament List
			fetchParliament();
		}
		// eslint-disable-next-line
	}, []);

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (parliamentName) {
			const formData = new URLSearchParams();
			formData.append('name', parliamentName);
			formData.append('user_id', '1');

			try {
				const response = await axios.post('http://unitech.agency/Agency/Congress/admin/api/v1/add_Parliament', formData, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					}
				});

				if (response.status === 200 && response.data.status === 200) {
					// Add new Parliament to the list
					setParliaments([...parliaments, { name: parliamentName }]);
					setParliamentName(""); // Reset input after submit
				} else {
					alert(response.data.message || 'Failed to create Parliament. API returned an error!');
				}
			} catch (error) {
				console.error('API Error:', error);
				alert('Failed to create Parliament. Please try again!');
			}
		}
	};

	const fetchParliament = async() => {
		try {
			const response = await axios.get('http://unitech.agency/Agency/Congress/admin/api/v1/get_Parliament', {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				}
			});

			if (response.status === 200 && response.data.status === 200) {
				setParliaments(response.data.Parliament);
				setParliamentName(""); // Reset input after submit 
			} else {
				console.log(response.data.message || 'Failed to create city. API returned an error!');
			}
		} catch (error) {
			console.error('API Error:', error);
			alert('Failed to create Parliament. Please try again!');
		}
	};

	const handleDelete = async(id, index) => {
		try {
			const response = await axios.get('http://unitech.agency/Agency/Congress/admin/api/v1/remove_Parliament/'+id, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				}
			});

			if (response.status === 200 && response.data.status === 200) {							
				setParliaments(parliaments.filter((_, i) => i !== index));
			} else {
				alert(response.data.message || 'Failed to create city. API returned an error!');
			}
		} catch (error) {
			console.error('API Error:', error);
			alert('Failed to create Parliament. Please try again!');
		}
	};

	const handleEdit = (index) => {
		// Set editing state
		setEditingIndex(index);
		setEditParliamentName(parliaments[index].name);
	};

	const handleUpdate = async(id,index) => {

		if (editParliamentName) {
			const formData = new URLSearchParams();
			formData.append('id', id);
			formData.append('name', editParliamentName);
			formData.append('user_id', '1');

			try {
				const response = await axios.post('http://unitech.agency/Agency/Congress/admin/api/v1/edit_Parliament', formData, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					}
				});

				if (response.status === 200 && response.data.status === 200) {
					// Update Parliament name
					const updatedParliaments = [...parliaments];
					updatedParliaments[index].name = editParliamentName;
					setParliaments(updatedParliaments);
					setEditingIndex(null);
					setEditParliamentName(""); // Clear edit input
				} else {
					alert(response.data.message || 'Failed to create city. API returned an error!');
				}
			} catch (error) {
				console.error('API Error:', error);
				alert('Failed to create Parliament. Please try again!');
			}
		}
	};

	return (
		<div className="container-fluid bg-light min-vh-100">
			<h4 className="text-center">
				<img 
					src="../congressflag.png"  // Path to your image
					alt="Logo"
					style={{ width: '50px', height: '50px', marginRight: '10px' }}
				/>
				Parliament
			</h4>
			<p className="text-center">Add a new Parliament here</p>

			{/* Form for adding new Parliament */}
			<div>
				<form onSubmit={handleSubmit}>
					<div className="input-group" style={{ display: 'flex', justifyContent: 'space-between' }}>
						<TextField 
							label="Parliament Name" 
							variant="outlined" 
							size="small"
							value={parliamentName}
							onChange={(e) => setParliamentName(e.target.value)} 
							required
							style={{ flexGrow: 1 }}
						/>
						<Button 
							variant="contained" 
							color="primary" 
							type="submit" 
							style={{ marginLeft: '10px', alignSelf: 'center' }}
						>
							Add Parliament
						</Button>
					</div>
				</form>
			</div>

			{/* Table to display Parliaments */}
			<TableContainer component={Paper} style={{ marginTop: '20px' }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell><strong>Sl. No.</strong></TableCell>
							<TableCell><strong>Parliament Name</strong></TableCell>
							<TableCell><strong>Actions</strong></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{parliaments.map((parliament, index) => (
							<TableRow key={index}>
								<TableCell>{index + 1}</TableCell>
								<TableCell>
									{editingIndex === index ? (
										<TextField 
											value={editParliamentName}
											onChange={(e) => setEditParliamentName(e.target.value)}
											size="small"
											fullWidth
										/>
									) : (
										parliament.name
									)}
								</TableCell>
								<TableCell>
									{editingIndex === index ? (
										<Button 
											variant="contained" 
											color="primary" 
											onClick={() => handleUpdate(parliament.id,index)}
										>
											Update
										</Button>
									) : (
										<>
											<Button 
												variant="contained" 
												color="primary" 
												style={{ marginRight: '8px' }}
												onClick={() => handleEdit(index)}
											>
												Update
											</Button>
											<Button 
												variant="contained" 
												color="secondary"
												onClick={() => handleDelete(parliament.id,index)}
											>
												Delete
											</Button>
										</>
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

export default ParliamentMaster;